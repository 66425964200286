import React from "react";
import ProjectView from "../../../views/project-view";
import { graphql } from 'gatsby'    

const DesignProject = (props) => {
  const project = props.data.design
  const imgData = props.data.allFile
  const projects = props.data.allDesign && props.data.allDesign.nodes
  const location = props.location
  const medizinGesundheit = props.data.medizinGesundheit.nodes
  const kulturSoziales = props.data.kulturSoziales.nodes
  const mode = props.data.mode.nodes
  const wettbewerbe = props.data.wettbewerbe.nodes
  const categoryArray = {
    1: medizinGesundheit,
    2: kulturSoziales,
    3: mode,
    4: wettbewerbe
  }
    return(
      <ProjectView 
        project={project}
        projects={projects} 
        images={imgData}
        location={location}
        categoryArray={categoryArray} />
    )
  }

export default DesignProject


export const query = graphql`
query ($id: String, $category: String) {
  design(id: { eq: $id }) {
      title
      category
      slug
      _id
      city
      year
      description1
      description2
      links { 
        url
        text1
        text2
       }
      images {
        alt_1
        alt_2
        alt_3
        alt_4
        alt_5
        alt_6
        alt_7
        alt_8
        alt_9
        alt_10
      }
      gatsbyPath(filePath: "/design/{Design.category}/{Design.slug}")
    }
  allFile(filter: {sourceInstanceName: {eq: $category}, 
    extension: {regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/"},
    name: {regex: "/[a-zA-Z0-9][_][0-9]/"}}) {
    nodes {
      name
      childImageSharp {
        gatsbyImageData
      }
      relativePath
    }
  }
  allDesign {
    nodes{
			_id
      slug
      title
      category
    }
  }
  medizinGesundheit: allDesign(filter: {category: {eq: "medizin-gesundheit"}}) {
    nodes {
      title
      category
      _id
    }
  }
  kulturSoziales: allDesign(filter: {category: {eq: "kultur-soziales"}}) {
    nodes {
      title
      category
      _id
    }
  }
  mode: allDesign(filter: {category: {eq: "mode"}}) {
    nodes {
      title
      category
      _id
    }
  }
  wettbewerbe: allDesign(filter: {category: {eq: "wettbewerbe-konzepte"}}) {
    nodes {
      title
      category
      _id
    }
  }
}
`